import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/contact.module.css'
import linkedin from '../assets/linkedin.svg'
import github from '../assets/github.svg'
import instagram from '../assets/instagram.svg'
import { graphql } from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';



export default function Contact() {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <h1 className="header"><Trans>Contactez-moi</Trans></h1>

      <div className={styles.contact}>

        <div>
          <a href="https://linkedin.com/in/aija-dumceva" target="_blank"><img src={linkedin} className={styles.links} style={{borderRadius: "1rem"}}/></a>
          {/*<a href="https://github.com/aijabstract" target="_blank"><img src={github} className={styles.links}/></a>*/}
          <a href="https://instagram.com/aijabstract?igshid=OGQ5ZDc2ODk2ZA==" target="_blank"><img src={instagram} className={styles.links}/></a>
        </div>

        <form className={styles.form} method="POST" action="https://getform.io/f/59e19090-082a-49e7-a357-70a23b3322ad">
          <label>
            <input className={styles.input} type="email" name="email" placeholder="email" />
          </label>
          <label>
            <input className={styles.input} type="text" name="name" placeholder="name" />
          </label>
          <label>
            <input className={styles.input} style={{height: "10rem"}} type="text" name="message" placeholder="message" />
          </label>
          <button className={styles.btn} type="submit">Send</button>
        </form>

      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
  `;